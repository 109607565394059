<template>
  <div>
    <f2f-button text="+ Enroll Users" variant="success" @click="toggle(1)" />
    <b-modal v-model="showmodal" hide-footer hide-header centered>
      <div>
        <b-row class="border-style text-border">
          <b-col cols="8">
            <h5 class="my-2 enrollusers" v-if="classId == -1">Enroll Users</h5>
            <h5 class="my-2 enrollusers" v-if="classId > 1">+ Enroll Users</h5>
          </b-col>

          <b-col cols="4">
            <button
              type="button"
              class="btn btn-default cancelbutton"
              @click="toggle(-1)"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="addEnrolled"
              :disabled="!selectedClass || noLearnerSelected || submitted"
              class="btn btn-success savebutton"
            >
              <!-- <b-spinner v-if="submitted" small type="grow"></b-spinner> -->
              Save
            </button>
          </b-col>
        </b-row>
        <div v-if="classId == -1 && !selectedClass">
          <h6 class="selectclass">Select Class</h6>
          <f2f-select
            v-model="selectedClass"
            :options="classList"
            placeholder="Select Class"
          />
        </div>

        <div v-if="selectedClass">
          <div class="d-flex bd-highlight" style="margin-left: -10px">
            <div class="p-2 flex-fill bd-highlight studentclass">
              {{ placeAvailable }} places available for this location
            </div>
            <div class="p-2 flex-fill bd-highlight">
              <b-form-input
                v-model="searchTerm"
                placeholder="Find a learner"
                class="text-input"
              ></b-form-input>
            </div>
          </div>

          <div class="d-flex bd-highlight" style="margin-left: -10px">
            <div class="p-2 flex-fill bd-highlight">
              <b-form-checkbox
                id="checkbox-1"
                v-model="selectAll"
                name="checkbox-1"
                class="text-select"
                :disabled="(filterdLearners || []).length == 0"
              >
                {{ getSelectAllText }}
              </b-form-checkbox>
            </div>
            <!-- <div class="p-2 flex-fill bd-highlight">
              <select name="cars" id="cars" class="text-dropdown">
                <option value="Filter by">Filter by</option>
              </select>
            </div> -->
          </div>

          <b-row>
            <b-card-body id="nav-scroller" ref="content" class="scrollbar">
              <b-row
                class="spacing"
                v-for="(learner, i) in filterdLearners"
                :key="i"
              >
                <div class="d-flex bd-highlight">
                  <div class="p-2 flex-fill bd-highlight mt-1">
                    <b-form-checkbox v-model="learner.isChecked">
                    </b-form-checkbox>
                  </div>
                  <div class="p-2 flex-fill bd-highlight">
                    <span class="avtarimage">
                      <img src="@/assets/no-user.png" alt="" />
                      <span class="username"
                        >{{ learner.first_name }} {{ learner.last_name }}</span
                      >
                    </span>
                  </div>
                </div>
              </b-row>
              <b-row v-if="(filterdLearners || []).length == 0">
                <b-col>
                  <div class="username text-center">No Learner found</div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-row>
        </div>
      </div>
    </b-modal>
    <f2f-modal
      :display="onError"
      @hide="onError = false"
      type="danger"
      :title="errortitle"
      :body="error"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    classId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      submitted: false,
      selectedClass: null,
      onError: false,
      showmodal: false,
      searchTerm: "",
      errortitle: "",
      error: "",
      selectAll: false
    };
  },
  async mounted() {
    this.$store.dispatch("listAllLearners", this.$route.params.id);
    this.$store.dispatch("listAllClasses", {
      course_id: this.$route.params.id
    });
    this.selectedClass = this.classId === -1 ? null : this.classId;
  },
  computed: {
    ...mapGetters([
      "getAllClasses",
      "getNotification",
      "getLoadingAllClasses",
      "getAllLearners"
    ]),
    noLearnerSelected() {
      return (
        ((this.filterdLearners || []).filter((le) => le.isChecked) || [])
          .length == 0
      );
    },

    classList() {
      return (this.getAllClasses || [])
        .filter((classObj) => {
          return this.canEnroll(classObj);
        })
        .map((cls) => {
          return {
            value: cls.id,
            text: cls.title
          };
        });
    },
    placeAvailable() {
      if (this.classId != -1) {
        return (
          (this.classList.find((cls) => cls.value == this.classId) || {})
            .max_capacity || 0
        );
      } else {
        return 0;
      }
    },
    getSelectAllText() {
      let text = "Select all";
      const selectedLearner =
        (this.filterdLearners || []).filter((l) => l.isChecked) || [];
      if ((selectedLearner || []).length > 0) {
        text = `${text} (${(selectedLearner || []).length} selected`;
        if (this.waitingCount > 0) {
          text = `${text}/${this.waitingCount} in waiting list)`;
        } else {
          text = `${text})`;
        }
      }
      return text;
    },
    waitingCount() {
      const wList = (this.filterdLearners || []).find((s) => s.isWaiting);
      return (wList || []).length;
    },
    filterdLearners() {
      return (this.getAllLearners || []).filter((learner) => {
        if (this.searchTerm) {
          return `${learner.first_name} ${learner.last_name}`
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase());
        } else {
          return true;
        }
      });
    }
  },
  watch: {
    ["selectAll"]: function (selected) {
      for (let l = 0; l < (this.getAllLearners || []).length; l++) {
        this.getAllLearners[l].isChecked = selected;
        this.filterdLearners[l].isChecked = selected;
      }
    }
  },
  methods: {
    async addEnrolled() {
      const users = this.getAllLearners
        .filter((l) => l.isChecked)
        .map((learner) => {
          return {
            first_name: learner.first_name,
            last_name: learner.last_name,
            full_name: learner.full_name,
            email: learner.email,
            username: learner.username,
            enrollment_date: new Date(),
            enrollment_status: "Enrolled"
          };
        });
      const toPost = {
        class_id: this.selectedClass,
        course_id: this.$route.params.id,
        payload: {
          users: users
        }
      };

      try {
        this.submitted = true;
        await this.$store.dispatch("addEnrolledUser", toPost);
        this.$router.push({
          name: "enrollement",
          params: { classId: this.selectedClass }
        });
        this.submitted = false;
      } catch (err) {
        this.errortitle = "Unable to enroll users";
        this.submitted = false;
        this.onError = true;
        this.error = "Sorry, we are unable to enroll users";
        //console.log(err);
        // if(err?.response?.error) {
        //   this.onError = true;
        //   this.error = err.response.error;
        // } else if (err?.response?.data) {
        //   this.error = Object.keys(err?.response?.data)
        //     .map((key) => err?.response?.data[key][0])
        //     .join(",");
        //   this.onError = true;
        // }
      }
    },
    toggle(value) {
      // this.selectedClass
      if (value == -1) {
        for (var i = 0; i < this.filterdLearners.length; i++) {
          this.filterdLearners[i].isChecked = false;
        }
      }
      this.showmodal = !this.showmodal;
    }
  }
};
</script>
<style lang="scss" scoped>
.border-style {
  border-bottom: none;
  margin-bottom: 15px;
}

.enrollusers {
  font-family: Open Sans;
  font-weight: 600;
  line-height: 24px;
  color: #4c4c4c;
}

.cancelbutton {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #a0a4a8;
}

.savebutton {
  background: #0f8012;
  border-radius: 4px;
}

.selectclass {
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
  color: #52575c;
}

// .studentclass {
//   font-family: Open Sans;
//   font-size: 12px;
//   line-height: 16px;
//   color: #757575;
// }
.studentclass {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #52575c;
}

.text-input {
  border: 1px solid #a0a4a8;
  box-sizing: border-box;
  border-radius: 10px;
  float: right;
  width: 90%;
}

.text-select {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #757575;
}

// .text-dropdown {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   letter-spacing: 0.2px;
//   border: none;
//   color: #757575;
//   float: right;
// }
.text-chechbox {
  margin: auto;
  margin-right: -6px;
}

.text-border {
  border-bottom: groove;
}

.learnerclass {
  font-family: Open Sans;
  font-size: 10px;
  line-height: 2rem;
  color: #a0a4a8;
}

.inputbox {
  border: 1px solid #b0b6c2;
  border-radius: 0.5rem;
}

.scrollbar {
  position: relative;
  height: 300px;
  overflow-y: scroll;
}

.spacing {
  margin-bottom: 1rem;
}

.username {
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #757575;
}

.badges {
  background: #dceaff;
  border-radius: 100px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: #4488f4;
}

.avtarimage img {
  width: 35px;
  background: #8b8686;
  border-radius: 20px;
  margin-right: 15px;
}

::v-deep {
  thead {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #a0a4a8;
  }
}

::v-deep tbody {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.learner-text {
  color: #25282b;
}

.Email-text {
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #52575c;
}

button:disabled,
button[disabled] {
  border-radius: 4px;
  background-color: #717171 !important;
  border-color: #717171 !important;
}
</style>
